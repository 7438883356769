html {
  height:100%;
  -webkit-touch-callout: none;
}
body {
  height:100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
#root {
  height: 100%;
}
#container {
  height: 100%;
}

/* ローディング */
#loading {
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  margin: auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow:
      0 -30px 0 0 #bbb,
      15px -26px 0 0 #aaa,
      26px -15px 0 0 #999,
      30px 0 0 0 #888,
      26px 15px 0 0 #777,
      15px 26px 0 0 #666,
      0 30px 0 0 #555,
      -15px 26px 0 0 #444,
      -26px 15px 0 0 #333,
      -30px 0 0 0 #222,
      -26px -15px 0 0 #111,
      -15px -26px 0 0 #000;
  animation: loading 1s infinite steps(12, start);
}
@keyframes loading {
0% {
  transform: rotate(0deg);
  }
100% {
  transform: rotate(360deg);
  }
}

/* FAQ */
.faq_section_links {
  padding-left: 0;
  display: flex;
  justify-content: center;
}
.faq_section_links li {
  margin-right: 18px;
  list-style-type: none;
  font-size: 16px;
  color: rgb(64, 64, 64);
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .faq_section_links {
    display: block;
  }
  .faq_section_links li {
    margin-right: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
